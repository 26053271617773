import { Button, Checkbox, Stack, Text, Group, Modal } from '@mantine/core';
import { every, groupBy, keys, reduce, some } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';

import { ModalBody, ModalFooter } from '@portals/core';
import { CommonFeatureFlagsType } from '@portals/types';

enum CheckboxStatus {
  Checked = 'checked',
  Empty = 'empty',
  Indeterminate = 'indeterminate',
}

interface FeatureOption<T> {
  id: Extract<keyof T, string>;
  label: string;
}

export interface FeatureFlagsModalProps<T extends CommonFeatureFlagsType> {
  closeMe: () => void;
  featuresToDisplay: Array<FeatureOption<T>>;
  features?: T;
  onSubmit: (updatedFeatures: Partial<T>) => void;
}

function FeatureFlagsModal<T extends CommonFeatureFlagsType>({
  closeMe,
  onSubmit,
  features,
  featuresToDisplay,
}: FeatureFlagsModalProps<T>) {
  // We need this because the indeterminate attr of the "Select all" checkbox
  // coming from the change event is not updated for some reason.
  // So we're using this ref to keep track of it's correct status.
  const isIndeterminate = useRef(false);

  // Using a useState and not useRef because during the first run
  // of the effect that updates the "Select all" checkbox the ref is not ready yet.
  const [selectAllCheckboxRef, setSelectAllCheckboxRef] =
    useState<HTMLInputElement | null>(null);

  const [updatedFeatures, setUpdatedFeatures] = useState(
    mapRelevantFeatures(featuresToDisplay, features)
  );

  const updateFeature = (name: Extract<keyof T, string>) =>
    setUpdatedFeatures((currFeatures) => ({
      ...currFeatures,
      [name]: !currFeatures?.[name],
    }));

  const onSubmitHandler = () => {
    onSubmit(updatedFeatures);
    closeMe();
  };

  const onChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    let finalChecked;

    if (!isIndeterminate.current) finalChecked = checked;
    else finalChecked = false;

    const newUpdatedFeatures = keys(updatedFeatures).reduce<Partial<T>>(
      (acc, featureName) => {
        acc[featureName as keyof T] = finalChecked;
        return acc;
      },
      {}
    );

    setUpdatedFeatures(newUpdatedFeatures);
  };

  useEffect(
    function updateSelectAllCheckboxStatus() {
      if (selectAllCheckboxRef === null) {
        return;
      }

      const checkboxStatus = calcSelectAllCheckboxStatus<T>(updatedFeatures);

      if (checkboxStatus === CheckboxStatus.Checked) {
        selectAllCheckboxRef.checked = true;
        selectAllCheckboxRef.indeterminate = false;
      } else if (checkboxStatus === CheckboxStatus.Indeterminate) {
        selectAllCheckboxRef.checked = false;
        selectAllCheckboxRef.indeterminate = true;
      } else {
        selectAllCheckboxRef.checked = false;
        selectAllCheckboxRef.indeterminate = false;
      }

      isIndeterminate.current = selectAllCheckboxRef.indeterminate;
    },
    [selectAllCheckboxRef, updatedFeatures]
  );

  const featuresByCategory = Object.entries(
    groupBy('category', featuresToDisplay)
  );

  return (
    <Modal opened={true} onClose={closeMe} title="Feature Flags">
      <ModalBody>
        <Checkbox
          ref={(el: HTMLInputElement) => setSelectAllCheckboxRef(el)}
          id="toggle_all"
          label="Select / Deselect all"
          checked={every(Boolean, updatedFeatures)}
          onChange={onChangeSelectAll}
          mt="xl"
        />

        <Group grow sx={{ width: '100%' }} align="start" mt="xl">
          {featuresByCategory.map(([category, features]) => (
            <Stack key={category}>
              <Text size="lg">{category}</Text>

              {features.map(({ id, label }) => (
                <Checkbox
                  key={id}
                  id={`toggle_${id}`}
                  label={label}
                  checked={!!updatedFeatures[id]}
                  onChange={() => updateFeature(id)}
                />
              ))}
            </Stack>
          ))}
        </Group>
      </ModalBody>

      <ModalFooter position="apart">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button onClick={onSubmitHandler}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
}

function mapRelevantFeatures<T extends CommonFeatureFlagsType>(
  featuresToDisplay: Array<FeatureOption<T>>,
  features?: T
) {
  if (!features) return {} as Partial<T>;

  return reduce<FeatureOption<T>, Partial<T>>(
    (acc, feature) => {
      acc[feature.id] = features[feature.id];

      return acc;
    },
    {},
    featuresToDisplay
  );
}

function calcSelectAllCheckboxStatus<T extends CommonFeatureFlagsType>(
  features: Partial<T>
) {
  if (every(Boolean, features)) {
    return CheckboxStatus.Checked;
  } else if (some(Boolean, features)) {
    return CheckboxStatus.Indeterminate;
  } else {
    return CheckboxStatus.Empty;
  }
}

export default FeatureFlagsModal;
